/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import React from 'react'
import { get, isFunction, compact } from 'lodash'
import { graphql } from 'gatsby'

import Credits from '../components/credits'
import Introduction from '../components/introduction'
import { InlineHeader } from '../components/page-header'
import PageWrapper from '../components/page-wrapper'
import ProductionBlocks from '../components/production-blocks'
import Section from '../components/section'
import Title from '../components/title'
import CenterCol from '../components/center-col'
import LongForm from '../components/long-form'
import Signposts, { mapSignpost } from '../components/signposts'
import PostPage from '../components/post-page'

import {
  Cont,
  TextBlock,
  FlexCols,
  FlexCol,
  Space,
  FlexColEmpty,
} from '../styles/els'

import {
  formatImage,
  renderHTML,
  formatDate,
  resolveButtons,
} from '../lib/helpers'
import { FullsizeImage, InlineImage } from '../lib/fragments'

import { setValue, useThemeUISetValue } from '../styles/utils'
import CallToAction from '../components/call-to-action'

const mapToCTA = (cta) => {
  const { text, image, buttons: _buttons } = cta

  return {
    heading: 'Get involved',
    text,
    buttons: resolveButtons(_buttons),
    image: image ? formatImage(image) : null,
    display: 'block',
  }
}

const Project = (props) => {
  const { data } = props

  const title = get(data, 'project.title')
  const pageColor = get(data, 'project.acfProject.pageColour')
  const relatedPage = get(data, 'project.acfProject.relatedPage.0')

  const header = get(data, 'project.acfProject.header')
  const introduction = get(data, 'project.acfProject.intro')
  const longFormContent = get(data, 'project.acfProject.longForm')

  const showCallToAction = get(data, 'project.acfProject.showCallToAction')
  const callToAction = showCallToAction
    ? mapToCTA(get(data, 'project.acfProject.getInvolved'))
    : null

  const sub = compact([
    get(relatedPage, 'title'),
    get(relatedPage, 'wpParent.node.title'),
  ]).slice(-1)

  const signpost = relatedPage
    ? mapSignpost(
        {
          related: relatedPage,
        },
        {
          pageColor: pageColor,
        }
      )
    : null

  return (
    <PostPage
      pageColor={pageColor}
      headerImage={header.type === 'image' ? formatImage(header.image) : null}
      headerVideo={header.type === 'video' ? header.video : null}
      title={title}
      sub={sub}
      introduction={introduction}
      longFormContent={longFormContent}
      callToAction={callToAction}
      signpost={signpost}
    />
  )
}

export const query = graphql`
  query ($id: String) {
    project: wpProject(id: { eq: $id }) {
      title
      uri
      acfProject {
        pageColour
        relatedPage {
          ... on WpPage {
            title
            uri
            acfPage {
              thumbnail {
                ...InlineImage
              }
            }
            wpParent {
              node {
                ... on WpPage {
                  title
                }
              }
            }
          }
        }
        header {
          type
          image {
            ...FullsizeImage
          }
          video {
            videoType
            mux
            embed
          }
        }
        intro
        longForm {
          __typename
          ... on WpProject_Acfproject_LongForm_Heading {
            heading
          }
          ... on WpProject_Acfproject_LongForm_Image {
            image {
              ...InlineImage
            }
          }
          ... on WpProject_Acfproject_LongForm_Text {
            text
          }
          ... on WpProject_Acfproject_LongForm_Video {
            videoType
            mux
            embed
            label
          }
        }
        showCallToAction
        getInvolved {
          image {
            ...FullsizeImage
          }
          text
          buttons {
            label
            type
            linksTo
            externalLink
            email
            internalLink {
              __typename
              ... on WpPage {
                title
                uri
                acfPage {
                  pageColour
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Project
